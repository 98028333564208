








import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import ManualForm from '@/components/forms/depository/ManualForm.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import ManagerDepositoryModule from '@/store/modules/manager/depository'
import { ManualResource } from '@/store/types'

@Component({
  components: {
    ManualForm,
  },
})
export default class DepositoryManualItem extends Mixins(NotifyMixin, PermissionsMixin) {
  private manual: ManualResource | null = null

  private get manualID () {
    return +this.$route.params.manualID
  }

  private mounted () {
    ManagerDepositoryModule.fetchManual(this.manualID)
      .then((response: ManualResource) => {
        this.manual = Object.assign({}, response)
      })
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: this.manual ? this.manual.title : 'Статья',
    }
  }
}
