
































import { isEqual } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import TextEditor from '@/components/_uikit/editor/TextEditor.vue'
import TiptapEditor from '@/components/_uikit/editor/TiptapEditor.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerDepositoryModule from '@/store/modules/manager/depository'
import { IEditorData, ManualResource } from '@/store/types'

interface IManualForm {
  data: IEditorData | string | null,
  title: string,
}

@Component({
  components: {
    TextEditor,
    TextInput,
    TiptapEditor,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class ManualForm extends Mixins(NotifyMixin) {
  @Prop({ default: null })
  private parentID!: string

  @Prop({ default: null })
  private manual!: ManualResource

  @Prop({ default: false })
  private readonly !: boolean

  private get isNewEditor() {
    if (this.form.data === null)
      return true
    return typeof this.form.data === 'string'
  }

  private isReady = false
  private form: IManualForm = {
    data: null,
    title: '',
  }

  private mounted () {
    if (this.manual) {
      this.syncForm(this.manual)
    } else {
      this.isReady = true
    }
  }

  private syncForm (value: ManualResource) {
    this.form = {
      data: value.content ? JSON.parse(value.content) : null,
      title: value.title,
    }
    this.isReady = true
  }

  @Debounce(300)
  @Bind
  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          ManagerDepositoryModule.saveManual( {
            manualID: this.manual?.id,
            params: {
              ...this.form,
              content: JSON.stringify(this.form.data),
              parentId: this.parentID || undefined,
            },
          })
            .then((response: ManualResource) => {
              this.notifySuccess('Статья сохранена')
              requestAnimationFrame(() => (form.reset()))
              ManagerDepositoryModule.fetchManualTree()
              this.$router.push({
                name: 'manager.bank.depository.manual.item',
                params: { manualID: response.id.toString() },
              })
            })
            .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  @Watch('manual')
  private watchManual (value: ManualResource, oldValue: ManualResource) {
    if (!isEqual(value, oldValue)) {
      this.syncForm(value)
    }
  }
}
